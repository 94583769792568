<template>
  <div class="">
    <v-icon v-if="edit" medium class="me-2" @click="onEditItem">
      {{ icons.mdiPencilOutline }}
    </v-icon>
    <v-icon v-if="destroy" medium @click="onDestroyItem">
      {{ icons.mdiDeleteOutline }}
    </v-icon>
    <v-icon v-if="view" medium @click="onViewItem">
      {{ icons.mdiEyeCheckOutline }}
    </v-icon>
  </div>
</template>

<script>
import { mdiPencilOutline, mdiDeleteOutline, mdiEyeCheckOutline } from "@mdi/js";

export default {
  props: {
    edit: {
      default: false,
      type: Boolean
    },
    destroy: {
      default: false,
      type: Boolean
    },
    view: {
      default: false,
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const onEditItem = () => { emit('onEditItem') };
    const onDestroyItem = () => { emit('onDestroyItem') };
    const onViewItem = () => { emit('onViewItem') };
    return {
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiEyeCheckOutline
      },
      onEditItem,
      onDestroyItem,
      onViewItem
    };
  },
};
</script>

<style></style>
