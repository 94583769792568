<template>
  <div class="">
    <v-tooltip bottom color="info">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          small
          color="info"
          @click="onDrawerToggle(true)"
          :disabled="loading"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>
            {{ icons.mdiStar }}
          </v-icon>
        </v-btn>
      </template>
      <span>VALORAR SERVICIO </span>
    </v-tooltip>

    <WrapperDrawer
      title="AGREGAR VALORIZACION"
      v-model="drawerToggle"
      @close="onDrawerToggle"
    >
      <v-form ref="addFormValorization" @submit.prevent="handleFormSubmit">
        <FormTextArea
          label="Describa cómo fue el servicio prestado"
          v-model="addFormValorization.comment"
          :rules="[validators.required]"
          class="my-8"
        />

        <FormSelectField
          v-model="addFormValorization.stars"
          label="Valoración"
          :items="stars"
          itemText="label"
          itemValue="value"
        />

        <v-row>
          <v-col>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="success"
              type="submit"
              class="float-right mt-10"
            >
              Agregar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </WrapperDrawer>
  </div>
</template>

<script>
import { ref, reactive, getCurrentInstance } from "@vue/composition-api";
import WrapperDrawer from "@/components/general/WrapperDrawer";
import FormTextArea from "@/components/forms/TextArea";
import FormSelectField from "@/components/forms/Select";

import api from "@/services";
import { required } from "@core/utils/validation";
import { mdiStar } from "@mdi/js";

export default {
  props: {
    workerId: {
      type: Number,
      required: true,
    },
    quotationId: {
      type: Number,
      required: true,
    },
  },
  components: {
    WrapperDrawer,
    FormTextArea,
    FormSelectField,
  },

  setup(props, { emit }) {
    const { workerId: _workerId, quotationId } = props;

    const vm = getCurrentInstance().proxy;

    const loading = ref(false);
    const drawerToggle = ref(false);

    const stars = reactive([1, 2, 3, 4, 5]);

    const addFormValorization = reactive({
      stars: 1,
      comment: null,
      worker_id: _workerId,
      quotation_id: quotationId,
    });

    const onChangeFormInputs = (value, property) => {
      addFormValorization[property] = value;
    };

    const onDrawerToggle = (value = false) => {
      drawerToggle.value = value;
    };

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const handleFormSubmit = async () => {
      const refAddFormValorization = vm.$refs.addFormValorization;

      if (!refAddFormValorization.validate()) return;

      onToggleLoading();

      try {
        await api.addWorkersRatings(addFormValorization);

        onDrawerToggle();

        vm.$alert("Valoración añadida exitosamente", null, "success");
        refAddFormValorization.reset();

        emit("on-success");
      } catch (error) {
        vm.$alert("Ocurrio un error al guardar la información", null, "error");
      }

      onToggleLoading();
    };

    return {
      addFormValorization,
      loading,
      drawerToggle,
      stars,

      validators: {
        required,
      },

      icons: {
        mdiStar,
      },

      onDrawerToggle,
      onChangeFormInputs,
      handleFormSubmit,
    };
  },
};
</script>

<style></style>
