<template>
  <v-tooltip bottom color="error">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        small
        color="error"
        @click="setFavoriteQuoteById"
        :disabled="loading"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon dark>
          {{ buttonIcon }}
        </v-icon>
      </v-btn>
    </template>
    <span>{{ buttonText }} </span>
  </v-tooltip>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from "@vue/composition-api";
import { mdiHeartRemove, mdiHeartPlus } from "@mdi/js";

import api from "@/services";

export default {
  props: {
    quotationId: {
      default: null,
      type: Number,
    },
    favorite: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const { favorite, quotationId: _quotationId } = props;

    const vm = getCurrentInstance().proxy;
    const _favorite = ref(favorite);
    const loading = ref(false);

    const quotationId = computed(() => _quotationId);
    const buttonIcon = computed(() =>
      _favorite.value ? mdiHeartRemove : mdiHeartPlus
    );
    const buttonText = computed(() =>
      _favorite.value ? "QUITAR FAVORITA" : "AÑADIR FAVORITA"
    );

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const setFavoriteQuoteById = async () => {
      onToggleLoading();
      try {
        const value = !_favorite.value;
        await api.setFavoriteQuoteById(quotationId.value, {
          value,
        });

        _favorite.value = value;
      } catch (error) {
        vm.$alert("Ocurrio un error, intente nuevamente", null, "error");
      }
      onToggleLoading();
    };

    watch(
      () => [props.favorite],
      (items) => {
        _favorite.value = items[0];
      }
    );

    return {
      loading,
      buttonIcon,
      buttonText,

      setFavoriteQuoteById,
    };
  },
};
</script>

<style></style>
