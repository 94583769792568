<template>
  <v-container v-if="project" class="mt-16">
    <v-tabs
      class="my-4 wrapper-tabs"
      hide-slider
      style="box-shadow: none !important"
    >
      <v-tab :href="`#quote`">Información Cotización</v-tab>
      <v-tab :href="`#profile`" v-if="worker">Datos Profesional</v-tab>

      <v-tab-item value="quote">
        <v-row>
          <v-col sm="12" md="8" lg="8">
            <CardSection title="Información Cotización">
              <v-row>
                <v-col sm="12" xs="12" md="12" lg="8">
                  <div class="d-flex ga-3 flex-column gap-16">
                    <div class="item">
                      <h4 class="h-4">Descripción</h4>
                      <p class="my-0">{{ quotation.description }}</p>
                    </div>
                    <div class="item">
                      <h4 class="h-4">Garantia del servicio o producto</h4>
                      <p class="my-0">{{ quotation.warranty }}</p>
                    </div>
                    <div class="item">
                      <h4 class="h-4">Formas de pago</h4>
                      <p class="my-0">{{ quotation.payment_method }}</p>
                    </div>
                    <div class="item">
                      <h4 class="h-4">Adjuntos</h4>
                      <TagFiles
                        :attachments="quotation.attachments"
                        class="mt-4"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col sm="12" xs="12" md="12" lg="4">
                  <div class="d-flex justify-end gap-10">
                    <AddValorization
                      :workerId="worker.id"
                      :quotationId="quotationId"
                      v-if="isQuotationValorized"
                      @on-success="onSuccessValorized"
                    />

                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          color="success"
                          @click="setWinQuoteById"
                          :disabled="loading"
                          v-if="!isQuotationWin"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon dark>
                            {{ icons.mdiThumbUp }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>MARCAR COMO GANADA </span>
                    </v-tooltip>

                    <UpdateFavorite
                      :quotationId="quotationId"
                      :favorite="isQuotationFavorite"
                      class="my-4"
                    />

                    <DownloadPDF :quotation="quotation" />
                  </div>
                </v-col>
              </v-row>
            </CardSection>

            <CardSection title="Información del proyecto">
              <div class="d-flex ga-3 flex-column gap-16">
                <div class="item">
                  <h4 class="h-4">Titulo</h4>
                  <p class="my-0">{{ project.name }}</p>
                </div>
                <div class="item d-flex align-center gap-10">
                  <h4 class="h-4">Estado:</h4>
                  <ProjectStatus :status="project.status" />
                </div>
              </div>
            </CardSection>
          </v-col>
          <v-col sm="12" md="4" lg="4">
            <CardSection title="Historial mensajes">
              <ChatQuotation :quotation="quotation" />
            </CardSection>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="profile">
        <InfoProfile :user="worker" show-rating></InfoProfile>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import {
  ref,
  onMounted,
  computed,
  getCurrentInstance,
} from "@vue/composition-api";
import CardSection from "@/components/general/CardSection";
import ProjectStatus from "@/components/general/projects/Status";
import BtnTableActions from "@/components/general/BtnTableActions";
import UpdateFavorite from "@/components/company/quotations/UpdateFavorite";
import api from "@/services";
import router from "@/router";
import AddValorization from "@/components/company/quotations/AddValorization";
import InfoProfile from "@/components/worker/InfoProfile";
import DownloadPDF from "@/components/company/quotations/DownloadPDF";
import TagFiles from "@/components/general/TagFiles";
import TitleSection from "@/components/general/TitleSection";
import ChatQuotation from "@/components/general/ChatQuotation.vue";

import { mdiThumbUp } from "@mdi/js";

export default {
  name: "CompanyProjectDetail",
  components: {
    CardSection,
    ProjectStatus,
    BtnTableActions,
    UpdateFavorite,
    AddValorization,
    InfoProfile,
    DownloadPDF,
    TagFiles,
    TitleSection,
    ChatQuotation,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const quotation = ref(null);

    const loading = ref(false);

    const project = computed(() => {
      return quotation.value?.project;
    });
    const worker = computed(() => quotation.value?.worker);
    const quotationId = computed(() => quotation.value.id);
    const isQuotationWin = computed(() => !!quotation.value.win);
    const isQuotationValorized = computed(
      () => !quotation.value.is_valorized && isQuotationWin.value
    );
    const isQuotationFavorite = computed(() => !!quotation.value.favorite);

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const getProjectQuotationById = async (params) => {
      try {
        const { data: response } = await api.getProjectQuotationById(params);
        const { data } = response;
        quotation.value = data;
      } catch (error) {}
    };

    const setWinQuoteById = async () => {
      onToggleLoading();
      try {
        const { data: response } = await api.setWinQuoteById(quotationId.value);
        const { data } = response;
        quotation.value = data;
      } catch (error) {
        vm.$alert("Ocurrio un error, intente nuevamente", null, "error");
      }
      onToggleLoading();
    };

    const onSuccessValorized = () => {
      quotation.value.is_valorized = true;
    };

    onMounted(() => {
      const { projectId, quotationId } = router.currentRoute.params;

      if (projectId && quotationId)
        getProjectQuotationById({ projectId, quotationId });
    });

    return {
      quotation,
      quotationId,
      worker,
      isQuotationWin,
      isQuotationValorized,
      isQuotationFavorite,
      project,
      loading,

      icons: {
        mdiThumbUp,
      },

      setWinQuoteById,
      onSuccessValorized,
    };
  },
};
</script>

<style lang="scss" scope></style>
