<template>
  <div class="">
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          small
          color="primary"
          @click="generateReport"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>
            {{ icons.mdiDownload }}
          </v-icon>
        </v-btn>
      </template>
      <span>DESCARGAR COTIZACIÓN </span>
    </v-tooltip>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :htmlToPdfOptions="pdfOptions"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <v-app>
          <div class="d-flex align-center justify-space-between">
            <img width="200px" src="@/assets/images/app/logo_principal.png" />
            <h1 class="mb-6 mt-3">Cotización #{{ quotationId }}</h1>
          </div>
          <div class="mt-4">
            <h5>
              <strong>Titulo proyecto</strong>
            </h5>
            <p>{{ project.name }}</p>
          </div>
          <div class="">
            <h5>
              <strong>Garantía del servicio o producto</strong>
            </h5>
            <p>{{ quotation.warranty }}</p>
          </div>

          <div class="">
            <h5>
              <strong>Descripción</strong>
            </h5>
            <p>{{ quotation.description }}</p>
          </div>

          <div class="">
            <h5>
              <strong>Formas de pago</strong>
            </h5>
            <p>{{ quotation.payment_method }}</p>
          </div>

          <div class="">
            <InfoProfile :user="worker">
              <template #title>
                <h3 class="my-5">DATOS DE CONTACTO DEL PROVEEDOR</h3>
              </template>
            </InfoProfile>
          </div>
        </v-app>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from "@vue/composition-api";
import VueHtml2pdf from "vue-html2pdf";
import CardSection from "@/components/general/CardSection";
import ProjectStatus from "@/components/general/projects/Status";
import InfoProfile from "@/components/worker/InfoProfile";
import Logo from "@/components/general/Logo";
import { mdiDownload } from "@mdi/js";

export default {
  props: {
    quotation: {
      default: null,
    },
  },
  components: {
    VueHtml2pdf,
    CardSection,
    ProjectStatus,
    InfoProfile,
    Logo,
  },
  setup(props) {
    const { quotation } = props;
    const vm = getCurrentInstance().proxy;

    const _quotation = ref(quotation);
    const quotationId = computed(() => _quotation.value.id);
    const project = computed(() => {
      return _quotation.value?.project;
    });
    const worker = computed(() => _quotation.value?.worker);

    const pdfOptions = computed(() => ({
      filename: `Cotización-#${quotationId.value}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: { after: ".break" },
      margin: [0.1, 0.5],
    }));

    const generateReport = () => {
      vm.$refs.html2Pdf.generatePdf();
    };

    return {
      _quotation,
      quotationId,
      worker,
      project,
      pdfOptions,

      icons: {
        mdiDownload,
      },

      generateReport,
    };
  },
};
</script>

<style lang="scss" scoped>
.theme--light.v-application {
  background: #fff !important;
}
</style>
